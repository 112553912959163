@font-face {
  font-family: 'Graphik Regular';
  src: url('../fonts/Graphik-400-Regular.otf') format('OpenType');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Medium';
  src: url('../fonts/Graphik-500-Medium.otf') format('OpenType');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Semibold';
  src: url('../fonts/Graphik-600-Semibold.otf') format('OpenType');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}