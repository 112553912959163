@for $i from 1 through 20 {
  .mt-#{$i} {
    margin-top: $i + rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i + rem !important;
  }
  .mr-#{$i} {
    margin-right: $i + rem !important;
  }
  .ml-#{$i} {
    margin-left: $i + rem !important;
  }
}

  .mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.grid {
  display: flex;
  flex-flow: row wrap;

  //margin: -15px;
  &-item {
    @include flex-grid(1);
    padding: 15px 0;
  }
}

@for $i from 1 through 5 {
  .grid-#{$i} .grid-item {
    @include flex-grid(#{$i});
  }
}

@media all and (min-width: 576px) {
  @for $i from 1 through 10 {
    .mt-sm-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-sm-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-sm-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .section {
    padding: $section-gutter 0;
    > .d-flex {
      margin: -$section-gutter 0;
      padding: $section-gutter 0;
    }
  }
}

@media all and (min-width: 768px) {
  @for $i from 1 through 10 {
    .mt-md-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-md-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-md-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

@media all and (min-width: 1024px) {
  @for $i from 1 through 10 {
    .mt-lg-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-lg-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-lg-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 30px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

@media all and (min-width: 1280px) {
  @for $i from 1 through 10 {
    .mt-xl-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-xl-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  @for $i from 1 through 5 {
    .grid-xl-#{$i} .grid-item {
      @include flex-grid(#{$i});
    }
  }

  $grid-gutter: 30px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}