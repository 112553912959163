$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1280px
);

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: $grid-breakpoints(xs);
  --breakpoint-sm: $grid-breakpoints(sm);
  --breakpoint-md: $grid-breakpoints(md);
  --breakpoint-lg: $grid-breakpoints(lg);
  --breakpoint-xl: $grid-breakpoints(xl);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

//GENERAL//
$header-padding: 50px;
$section-gutter: 3rem;

//FONTS//

$font-stack-primary: "Graphik Regular", sans-serif;
$font-stack-secondary: "Graphik Semibold", sans-serif;
$font-size: 14px;
$p-line-height: 1.325;

//FONTS//

$bg-body: #fff;
$color-primary: #12182B;
$color-secondary: #1659EF;
$color-accent: #A369FE;
$color-gray: #5E6478;
$color-light: #D8D3D9;
$color-dark: #1d1d3b;
$color-body: #fff;

//HEADING SIZING
$h1-font-size: 3.5rem;
$h2-font-size: $h1-font-size - $h1-font-size * 20 / 100;
$h3-font-size: $h2-font-size - $h2-font-size * 20 / 100;
$h4-font-size: $h3-font-size - $h3-font-size * 20 / 100;
$h5-font-size: $h4-font-size - $h4-font-size * 25 / 100;
$h6-font-size: $h5-font-size - $h5-font-size * 25 / 100;

//MIXINS//

@mixin overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin flex-grid($col) {
  flex: 0 0 calc(100% / #{$col});
  max-width: calc(100% / #{$col});
}

@mixin grid($percentage) {
  flex: 0 0 $percentage;
  max-width: $percentage;
}

@mixin flex($col) {
  flex: 0 0 calc(100% / #{$col});
}

@mixin svg($desiredWidth, $originalWidth, $originalHeight) {
  width: $desiredWidth;
  height: calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $desiredWidth calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
}

@mixin height($desiredWidth, $originalWidth, $originalHeight) {
  height: calc(#{$desiredWidth} * #{$originalHeight} / #{$originalWidth});
}

@mixin thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin boxed($width) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

@mixin centered {
  margin-left: auto;
  margin-right: auto;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}