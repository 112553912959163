/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/
@import 'custom';



@media all and (max-width: 1050px) {
  /* styles for browsers smaller than 1050px; */
  .section-rows{
    .row{
      &:first-of-type, &:nth-of-type(3){
        .content{
          margin-right: 0;
        }
      }
      &:nth-of-type(2),&:nth-of-type(4){
        .content{
          margin-left: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .how .image{
    width: 375px;
  }
  .cta-section .section-image-2{
    margin: 0 auto;
  }
  .hero-content-text {
    h1 {
      line-height: 60px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .section-rows .row-section{
    padding: 40px 0 0;
  }
}
@media only screen and (max-width: 1300px) {
  .what-is-hy-sc .image{
    width: 550px;
  }
}
@media only screen and (max-width: 1200px) {
  .what-is-hy-sc .image{
    width: 450px;
  }
}
@media only screen and (max-width: 650px) {

}
@media only screen and (max-width: 400px) {

}

@media only screen and (max-width: 1200px) {
  .cv-section {
    .image{
      width: auto;
      padding: 0 20px;
    }
    .section-title{
      padding: 20px;
    }
  }
  .hero-img{
    max-width: 530px;
  }

}
@media only screen and (max-width: 1042px) {
  .hero-img{
    max-width: 380px;
  }
}
@media only screen and (max-width: 900px) {

  .features {
    padding: 0 20px;
    .feature{
      align-items: flex-start;
      flex-direction: column;
      .content{
        width: auto;
      }
      .icon{
        margin-right: 0;
        margin-bottom: 30px;
        align-items: flex-start;
      }
    }
  }
  .contact-section .contact-grid{
    flex-direction: column;
    .contact-col{
      margin-bottom: 20px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
}
@media all and (max-width: 700px) {
  .section-title{
    h2 {
      line-height: 46px;
    }
    h3{
      line-height: 36px;
    }
  }
}
@media all and (max-width: 640px) {

}
@media all and (max-width: 720px) {

}
@media all and (max-width: 575px) {
}
@media all and (max-width: 578px) {

}
@media all and (max-width: 550px) {

  .navbar-menu .navbar-nav .nav-item > .nav-link p{
      max-width: 280px;
    }

}
@media all and (max-width: 890px) {
  .navbar-expand-md, .navbar-expand-md-invert {
    .navbar-column .navbar-nav .nav-item > .nav-link{
      font-size: 14px;
    }
  }
  .navbar-expand-md, .navbar-expand-md-invert {
    .navbar-column .navbar-nav .nav-item > .btn{
      font-size: 16px;
    }
  }
  .navbar-expand-md, .navbar-expand-md-invert {
    .navbar-column .navbar-nav .nav-item{
      padding: 0 0 0 30px;
    }
  }
}
@media all and (max-width: 1420px) {
  .hero-decor{
    &:before{
      background-position: bottom -30rem right 20%;
    }
  }
  .handpick-decor{
    &:before{
      background-position: top -10.9rem right 20%;
    }
  }

}
@media all and (max-width: 1000px) {
  .second-section .image{
    width: 240px;
    margin-right: 100px;
  }
  .careers .careers-img{
    margin-left: 100px;
    width: 235px;
  }
  .hero-content .hero-content-text {
    h2{
      line-height: 50px;
    }
    h3{
      margin-top: 30px;
    }
  }
  .essential{
    flex-direction: column;
    .card{
      width: auto;
      &:nth-of-type(2){
        margin-top: 30px;
      }
    }
  }
  .how{
    flex-direction: column;
    .content{
      padding: 30px 20px 0;
      width: auto;
    }
    .image{
      width: auto;
      padding: 0 130px 20px;
      margin-left: 0;
    }
  }
  .what-is-hy-sc{
    flex-direction: column-reverse;
    .image{
      width: auto;
      padding: 0 100px 20px;
      margin-left: 0;
    }
    .content{
      padding: 30px 20px 0;
      h3{
        width: auto;
      }
      .cards .card{
        width: auto;
        .content{
          padding: 0;
        }
      }
    }
  }
  .hero-content .hero-content-text{
    h1{
      line-height: 65px;
    }
  }
  .handpick .bg .handpick-section .image{
    max-width: 430px;
  }
  .hero-decor{
    &:before{
      background-position: bottom -30rem right 13%;
    }
  }
  .handpick-decor{
    &:before{
      background-position: top -10.9rem right 13%;
    }
  }
}
@media all and (max-width: 900px){
  .careers .careers-img{
    margin-left: 10px;
  }
  .second-section .image{
    margin-right: 10px;
  }
}

@media all and (max-width: 965px) {
  .handpick .bg .handpick-section{
    flex-direction: column;
    .content{
      width: auto;
      padding: 20px;
    }
    .image{
      padding-top: 100px;
    }
  }
  .hero-decor{
    &:before{
     display: none;
    }
  }
  .handpick-decor{
    &:before{
      background-position:  bottom 26rem left 35%;
    }
  }
}



@media all and (max-width: 440px) {
  .handpick-decor:before{
    background-position: bottom 40% left 35%;
  }
}
@media all and (max-width: 420px) {
  .handpick-decor:before{
    background-position: bottom 25% left 35%;
  }
}
@media all and (min-width: 768px) {

  $header-padding: 100px;
  $section-gutter: 4rem;

  .navbar-expand-md {
    padding: 40px 0;
    &-invert{
      //padding:, 40px 0;
      .navbar-nav {
      .nav-item > .nav-link:hover,
      .nav-item > .nav-link.active {
        color: white;
       }
      }
    }
  }

  .header-scrolled {
    .navbar {
     padding: 10px 0;
    }
  }

  .navbar-nav {
    align-items: center;

    .nav-item > .nav-link:hover,
    .nav-item > .nav-link.active {
      color: $color-secondary;
    }
  }

  #hero-home {
    > .d-flex {
      //padding-top: calc(#{$header-padding} + #{$section-gutter});
      //min-height: 100vh;
    }
  }

  .hero {
    > .d-flex {
      padding: #{$section-gutter*2} 0 0;
    }
  }

  .section {
    padding: #{$section-gutter} 0;
    > .d-flex {
      margin: -$section-gutter 0;
      padding: $section-gutter 0;
    }
  }

  .section-first {
    padding-top: calc(#{$header-padding} + #{$section-gutter / 2});
  }

  .navbar-column:nth-of-type(1) {
    .navbar-nav {
      .nav-item {
        margin: 0 2rem 0 0;
      }
    }
  }


  .navbar-menu-toggler {
    display: none;
  }

  .navbar-menu {
    display: none;
  }

}
@media all and (max-width: 1130px) {
  .split-section{
    flex-direction: column;
    .content{
      width: auto;
      &:nth-of-type(2){
        margin-top: 30px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  #careers .container{
    margin-top: 4rem;
  }
  #mission .container{
    margin-top: 4rem;
  }
  .second-section {
    flex-direction: column-reverse;
    .image{
      margin-right: 0;
      width: auto;
      padding: 40px 220px 0;
    }
    .content {
      width: auto;
      padding: 0 20px;
    }
  }
  .careers {
    .hero-c{
      flex-direction: column;
      .careers-img{
        margin-left: 0;
        width: auto;
        padding: 40px 220px 0;
      }
    }
    .hero-content-text {
      padding: 0 20px;
      p{
        max-width:100%;
      }
    }
  }
  .link-large{
    font-size: 24px;
  }
  .arrow-large{
    width: 30px;
    height: 30px;
  }
  .hero-content .hero-content-text {
    h2{
      line-height: 38px;
    }
    h3{
      margin-top: 20px;
    }
  }
  .dream-job-cards{
    flex-direction: column;
    .card{

      &:first-of-type{
        width: auto;
        margin-right: 0;
      }
      &:nth-of-type(2){
        margin-top: 30px;
        width: auto;
      }
    }
  }
  .how .content{
    padding: 30px 0 0;
  }
  .what-is-hy-sc .content{
    padding: 30px 0 0;
  }
  .center-section{
    padding: 50px 0;
  }
  .img-pagination-4{
    &:before{
      background-position: top -23.5rem right 50%;
    }
  }
  #score{
      padding-top: 100px;
  }
  .cta-section {
    .section-image-2{
      width: auto;
      padding: 50px 150px 0;
    }
    h1{
      font-size: 66px;
      line-height: 70px;
      margin: 16px 0;
    }
  }
  .cv-section {
    .image{
      width: auto;
      padding: 0 10px;
    }
    .section-title{
      padding: 10px;
    }
  }
  .copy{
    .copyright{
      display: none;
    }
  }
  .copy-bg .copyright2{
    display: block;
    margin-top: 30px;
  }
  .cta-section {
    h2{
      width: auto;
    }
    .section-image{
      margin-left: 0;
      margin-top: 4rem;
    }
  }
  .section-rows{
    .row{
      &:first-of-type, &:nth-of-type(3){
        .row-section{
          flex-direction: column-reverse;
        }
        .content{
          width: auto;
          padding: 20px;
        }
      }
      &:nth-of-type(2),&:nth-of-type(4){
        .row-section{
          flex-direction: column;
        }
        .content{
          margin-left: 0;
          width: auto;
          padding: 20px;
        }
      }
    }
  }
  .hero-content .hero-content-text h1 + p{
    margin: 28px 0;
  }
  .handpick {
    padding-top:20px;
    .bg .handpick-section .content{
      padding: 0 0 20px;
      margin-right: 0;
    }
  }
  .hero > .d-flex .hero-c{
    flex-direction: column;
    .hero-content{
      margin: 0;
      .hero-content-text{
        text-align: center;
      }
    }
    .hero-img{
      margin-top: 80px;
      max-width: 530px;
    }
  }
  .navbar-menu {
    display: none;

    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    min-height: 100vh;
    .navbar-nav {
      position: relative;
      z-index: 2;

      opacity: 0;
      transition: opacity 600ms ease;

      padding-top: calc(60px + 20px);
      padding-bottom: calc(28px + 12px);

      padding-left: 15px;
      padding-right: 15px;
      .nav-item{
        padding: 0;
        &:last-of-type{

          padding-top: 20px;
          .btn-accent{
            width: 100%;
          }
        }
      }
    }
    .navbar-nav .nav-item + .nav-item {
      margin-top: 15px;
    }
    .navbar-nav .nav-item > .nav-link {
      position: relative;

      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0;

      display: block;

      color: white;
      p{
        line-height: 26px;
      }
    }
    .navbar-nav .nav-item > .nav-link:after {
      content: '';

      border-style: solid;
      border-width: 2px 2px 0 0;

      display: inline-block;

      top: 50%;
      right: 4px;

      margin-top: -4px;

      position: absolute;

      transform: rotate(45deg);

      height: 8px;
      width: 8px;
    }
    &:before {
      content: '';

      position: absolute;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      transform: scaleY(0);
      transform-origin: center top;

      transition: transform 600ms cubic-bezier(0.16, 1, 0.3, 1) 300ms;

      background: #191C1F;
    }
  }

  .menu-open {
    &:before {
      transform: scaleY(1);
      transition-delay: 0ms;
    }
    .navbar-nav {
      opacity: 1;
      transition-delay: 300ms;
    }
    .btn {
      &-dark,
      &-dark:focus {
        background-color: white;
        border-color: white;
        color: $color-dark;
      }
      &-dark:hover,
      &-dark:active:focus {
        background-color: white;
        border-color: white;
        color: $color-dark;
      }
    }
  }

  .navbar-brand,
  .navbar-menu-toggler {
    position: relative;
    z-index: 3;

    transition: opacity 600ms ease, color 600ms ease;

  }

  .menu-is-open {
    .navbar-brand {
      max-height: 3.5rem;
      content: url('../img/logo-white.svg');
      transition: opacity 600ms ease, color 600ms ease;
    }
    .navbar-menu-toggler > span {
      background-color: #fff;
    }
  }

  .navbar-column:nth-of-type(2), .navbar-column:nth-of-type(3) {
    display: none;
  }

  .navbar-column:nth-of-type(1) {
    display: flex;
    justify-content: space-between;

    flex-grow: 1;
  }

  .navbar-brand {
    font-size: 1.25rem;
  }

  //#hero-home {
  //  > .d-flex {
  //    padding: calc(#{$section-gutter} + #{$header-padding}) 0 calc(#{$section-gutter} + #{$header-padding} + 2rem);
  //  }
  //}

  #hero-home {
    > .d-flex {
      padding-top: 4rem;
      //padding-bottom: 24rem;
    }
  }

  .grid-padding-sm {
    margin: -7.5px;
    .grid-item {
      padding: 7.5px;
    }
  }
}
@media all and (max-width: 600px) {
  .second-section {
    .image{
      padding: 40px 140px 0;
    }
    .content{
      padding: 0 10px;
    }
  }
    .careers {
      .hero-c .careers-img{
        padding: 40px 140px 0;
      }
      .hero-content-text {
        padding: 0 10px;
      }
    }

  #score, .sec-formalised {
    .background .score-content {
      h1{
        line-height: 90px;
        margin-bottom: 30px;
      }
    }
  }
  .features {
    padding: 0;
  }
  .how .image{
    width: auto;
    padding: 0 100px 20px;
    margin-left: 0;
  }
  .what-is-hy-sc .image{
    padding: 0 50px 20px
  }
  .cta-section .section-image-2 {
    padding: 50px 100px 0;
  }
}

@media all and (max-width: 500px) {
  .second-section {
    .image{
      padding: 40px 80px 0;
    }
    .content{
      padding: 0;
    }
  }
  .careers {
    .hero-c .careers-img{
      padding: 40px 80px 0;
    }
    .hero-content-text {
      padding: 0;
    }
  }
  .link-large{
    font-size: 20px;
  }
  .how .image{
    width: auto;
    padding: 0 50px 20px;
    margin-left: 0;
  }
  .cta-section .section-image-2 {
    padding: 50px 50px 0;
  }
  .section-rows{
    .row{
      &:first-of-type, &:nth-of-type(3){
        .content{
          padding: 10px;
        }
      }
      &:nth-of-type(2),&:nth-of-type(4){
        .content{
          padding: 10px;
        }
      }
    }
  }
  .contact-section .contact-grid .contact-col a{
    font-size: 22px;
  }
  .section-rows .row-section .image{
    width: auto;
    padding: 20px;
  }
}