/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/
@import 'custom';

body {
  background: $bg-body;
  color: $color-primary;
}

html, body {
  font-family: $font-stack-primary;
  font-size: $font-size;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;

  height: 100%;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  font-family: $font-stack-secondary;
  font-weight: 400;
}

p {
  line-height: $p-line-height;

  margin: 0;
}

a {
  color: $color-body;
}

a:hover,
a:active {
  color: $color-body;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

/*** Buttons Start ***/
.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.35px;
  font-family: "Graphik Medium", sans-serif;
  &-large{
    font-size: 32px;
    line-height: 46px;
  }
  &-colored,
  &-colored:focus {
    color: $color-secondary;
    &> i{
      transition: 0.5s;
      -webkit-transform: translateX(-5px);
      -moz-transform: translateX(-5px);
      -ms-transform: translateX(-5px);
      -o-transform: translateX(-5px);
      transform: translateX(-5px);
    }
  }
  &-colored:hover,
  &-colored:active:focus {
    color: $color-secondary;
    &> i{
      transition: 0.5s;
      -webkit-transform: translateX(6px);
      -moz-transform: translateX(6px);
      -ms-transform: translateX(6px);
      -o-transform: translateX(6px);
      transform: translateX(6px);
    }
  }
}
.arrow{
  margin-left: 10px;
  &-small{
    content:url("../img/arrow.svg");
  }
  &-large{
    content:url("../img/arrow-l.svg");
  }
}
.large-link{
  margin: 120px 0 0;
  text-align: center;
}
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 19px 32px;
  font-family: "Graphik Medium", sans-serif;
  border-radius: 16px;
  font-size: 18px;
  line-height: 18px;
  transition: background-color .15s ease, color .15s ease, border-color .15s ease;
  &-accent,
  &-accent:focus {
    background: $color-secondary;
    color: rgba(255,255,255, 1);
  }
  &-accent:hover,
  &-accent:active:focus {
    background: darken($color-secondary, 10%);
    color: white;
  }
  &-colored,
  &-colored:focus {
    border: 1px solid rgba(255,255,255, 1);
    background: $color-body;
    color: #1A1C1F;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  &-colored:hover,
  &-colored:active:focus {
    background-color: transparent;
    border: 1px solid rgba(255,255,255, 1);
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
}

/*** Buttons End ***/
.section {
  padding: $section-gutter 0;
  > .d-flex {
    margin: -$section-gutter 0;
    padding: $section-gutter 0;
  }
  &-dark{
    background-color: #1A1C1F;
  }
  &-light{
    background-color: #F6F9FB;
  }
}

.section-first {
  padding-top: calc(#{$header-padding} + #{$section-gutter / 2});
}

//Grid mods
$grid-gutter: 15px;

.container {
  width: 100%;
  max-width: 1380px;
  &-small{
    width: 100%;
    max-width: 1200px;
  }
  &-smaller{
    padding:3rem 0;
    width: 100%;
    max-width: 940px;
  }
}
.row {
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
  > .col,
  > [class*="col-"] {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}
.container-fluid {
  width: 100%;
}
*:focus {
  outline: 0 !important;
}

/*** Misc End ***/

/*** Header Start ***/

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2019;
}

.navbar-expand-md {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  min-height: 50px;
  transition: padding .20s ease;
  &-invert{
    .navbar-column{
      .navbar-nav{
        .nav-item > .nav-link {
          font-family: "Graphik Medium", sans-serif;
          color: rgba(255,255,255, 0.4);
          font-size: 18px;
          line-height: 20px;
        }
        .nav-item > .nav-link:hover {
          color: white;
        }
      }
      .navbar-menu-toggler > span{
        background: white;
      }
    }
  }
}

.navbar-nav {
  .nav-item{
    padding: 0 0 0 40px;
  }
  .nav-item > .nav-link {
    font-family: "Graphik Medium", sans-serif;
    color: $color-primary;
    font-size: 18px;
    line-height: 20px;

  }
  .nav-item > .nav-link:hover {
    background-color: transparent;
  }
  .nav-item > .nav-link:focus {
    background-color: transparent;
  }
}

.navbar-brand {
  transition: opacity 600ms ease;
  padding: 0;
  margin: 0;

  > img {
    max-height: 3.5rem;
  }
}

.navbar-menu-toggler {
  width: 20px;

  background: none;
  border: 0;
  padding: 0;
  > span {
    display: block;

    width: 100%;
    height: 2px;

    background: $color-primary;

    transition: width 200ms ease;
  }
  > span + span {
    margin-top: 4px;
  }
  &:hover > span {
    width: 100%;
  }
}
.header-scrolled {
  .navbar {
    background-color: $bg-body  ;
    box-shadow: 0 1px 2px rgba($color-dark, 0.1);
  }
  .navbar-brand {
    font-size: 1.5rem;
  }
}

/*** Header End ***/

/*** Hero Start ***/

#hero-home .container{
   margin-top: 7rem;
 }
#careers .container{
  margin-top: 14rem;
}
#mission .container{
  margin-top: 10rem;
}
.hero {
  //padding-bottom: 50px;
  position: relative;
  background: url("../img/hero-background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 99;

    width: 100%;
    padding: #{$section-gutter} 0;
    .hero-c{
      display: flex;
      align-items: center;

      justify-content: flex-end;
    }
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
}
.hero-img{
  max-width: 630px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.layout-left{
  margin-left: 150px;
}
.hero-content {
  margin-right: 70px;
  .hero-content-text {
    text-align: left;
    h2{
      @include fluid-type(768px, 1440px, 24px, 48px);
      line-height: 66px;
      color: $color-primary;
    }
    h3{
      @include fluid-type(768px, 1440px, 20px, 32px);
      line-height: 46px;
      margin-top: 48px;
      color: $color-secondary;
    }
    h1 {
      @include fluid-type(768px, 1440px, 50px, 80px);
      line-height: 82px;
      letter-spacing: -1.4px;
      color: $color-primary;

      span {
        color: $color-secondary;
      }
    }
    p {
      @include fluid-type(768px, 1440px, 20px, 20px);
      color: $color-primary;
      line-height: 32px;
      max-width: 380px;
    }
    h1 + p {
      margin: 48px 0;
    }
  }
}
/*** Hero End ***/

/** homepage **/
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  img{
    width: 100%;
  }
}

.copy-bg{
  padding: 80px 0;
  .copy{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .copy-links{
      a.copy-link{
        margin-left: 2px;
      }
    }
    .copyright{
      p{
        color: $color-gray;
        font-size: 16px;
      }
    }
  }
  .copyright2{
    display: none;
    p{
      color: $color-gray;
      font-size: 16px;
    }
  }
}

/* HEADINGS */
.section-title{
  h4{
    @include fluid-type(768px, 1440px, 28px, 32px);
    letter-spacing: -0.56px;
    line-height: 32px;
  }
  h2{
    @include fluid-type(768px, 1440px, 36px, 48px);
    line-height: 58px;
    letter-spacing: -0.84px;
    span{
        color: $color-secondary;
      }
  }
  h3{
    @include fluid-type(768px, 1440px, 28px, 32px);
    line-height: 46px;
    letter-spacing: -0.56px;
    font-family: "Graphik Medium", sans-serif;
  }
  p{
    @include fluid-type(768px, 1440px, 16px, 18px);
    line-height: 26px;
    color: $color-primary;
  }
  h2 + p{
    margin-top: 30px;
  }
  p + a, p + div{
    margin-top: 40px;
  }
}

[data-animate='true'] {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);

  transition: opacity .4s ease, transform .8s cubic-bezier(0.25, 1, 0.5, 1);

  will-change: transform, opacity;
}

[data-animate='true'].animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* HOMEPAGE */
.handpick {
  padding-top: 120px;
  .bg {
    position: relative;
    z-index: 2;
    .handpick-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        margin-right: 60px;
        width: 540px;

        .ul-check-list {
          list-style: none;
          padding: 40px 0;
          margin: 0;

          li + li {
            margin-top: 24px;
          }

          li {
            position: relative;
            line-height: 1.9rem;
            padding-left: 3rem;

            p {
              font-size: 16px;
              line-height: 23px;
            }

            p + p {
              margin-top: 30px;
            }
          }

          li:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 2rem;
            background: url('../img/checkmark.svg') no-repeat center center;
            background-size: contain;
          }
        }
      }

      .image {
        max-width: 530px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
.hero-decor{
  &:before{
    position: absolute;
    content: "";
    background: url('../img/line-left.png') no-repeat bottom -30rem right 20.25rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.handpick-decor{
  &:before{
    position: absolute;
    content: "";
    background: url('../img/line-left.png') no-repeat top -10.9rem right 20.25rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.cv-section{
  padding: 120px 0 0;
  background-color: #F6F9FB;
  border-radius: 20px;
  text-align: center;
  .image{
    width: 995px;
    margin: 30px auto 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h3 + p{
    margin-top: 30px
  }
  p{
    @include fluid-type(768px, 1440px, 18px, 20px);
    line-height: 32px;
    max-width: 640px;
    margin: auto;
  }
  p + p{
    margin-top: 20px;
  }
}

.section-rows{
  .row-section{
    padding: 120px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .content{
      width: 540px;
      .section-title{
        &:first-of-type{
          margin-bottom: 48px;
        }
      }
      p{
        @include fluid-type(768px, 1440px, 16px, 18px);
        color: $color-gray;
        line-height: 27px;
      }
      h4 + p{
        margin-top: 24px;
      }
      p+p{
        margin-top: 20px;
      }
    }
    .image{
      width: 420px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .row{
    &:first-of-type, &:nth-of-type(3){
      .content{
        margin-right: 120px;
      }
    }
    &:nth-of-type(2),&:nth-of-type(4){
      .content{
        margin-left: 120px;
      }
    }
  }
}

@for $i from 1 through 3 {
  #clients-#{$i} {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #clients-#{$i} .clients-wrap {
    display: block;
    width: 100%;
    height: 88px;
    margin: 0 auto;
    overflow: hidden;
  }

  #clients-#{$i} .clients-wrap ul {
    display: block;
    list-style: none;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  #clients-#{$i} .clients-wrap ul li {
    display: flex;
    float: left;
    position: relative;
    width: 430px;
    height: 88px;
    margin-left: 30px;

    .content {
      display: flex;
      padding: 20px;

      .icon{
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        border-radius: 100%;
      }

      .text {
        width: 216px;
        p{
          line-height: 20px;
          color: #7F7E8B;
          b{
            font-family: 'NeueHaasGroteskText Medium', sans-serif;
            color: #1B1A23;
          }
        }
      }
    }
  }
  #clients-#{$i} .clients-wrap ul li img {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    align-self: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: 0 linear left;
    -moz-transition: 0 linear left;
    transition: 0 linear left;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
    //filter: alpha(opacity=65);
    //opacity: 0.65;
  }
  #clients-#{$i} .clients-wrap ul li img:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1.0;
  }
}
.text{
  h4{
    color: $color-primary;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
  }
}
.visible-mark{
  padding: 30px 0;
  text-align: center;
  p{
    @include fluid-type(768px, 1440px, 18px, 20px);
    line-height: 29px;
    color: $color-gray;
    max-width: 540px;
    margin: 32px auto;}
  p+a{margin:0;}
  a{
    font-size: 18px;
  }
}
.bg-light{
  background-color: #F6F9FB;
  padding: 120px 0 0;
  border-radius: 20px;
}
.bg-light-2{
  background-color: #F6F9FB;
  padding: 100px 0;
  border-radius: 20px;
}
.cta-section{
  margin: auto;
  max-width: 1140px;
  .section-image{
    max-width: 870px;
    margin-left: 20rem;
    margin-top: -8rem;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .section-image-2{
    width: 598px;
    margin-left: 39rem;
    margin-top: -20rem;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h5{
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.56px;
    font-family: "Graphik Semibold", sans-serif;
  }
  p{
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 8px;
    color: $color-gray;
  }
  h1{
    //@include fluid-type(768px, 1440px, 36px, 48px);
    font-size: 80px;
    line-height: 80px;
    margin: 32px 0;
    span{
      color: $color-secondary;
    }
  }
  h2{
    width:540px;
    margin-bottom: 50px;
  }
}
.section-margin{
  margin-top: 80px;
}
.contact-section{

  .contact-us{
    text-align: center;
    max-width: 840px;
    margin: 0 auto 100px;
    a{
      color: $color-secondary;
      text-decoration: underline;
    }
  }
  .contact-grid{
    display: flex;
    justify-content: space-between;
    .contact-col{
      p{
        font-size: 16px;
        line-height: 20px;
        color: $color-gray;
      }
      a{
        font-family: "Graphik Medium", sans-serif;
        margin-top: 32px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.56px;
        color: $color-primary;
      }
    }
  }
}

/* CAREERS */
.careers {
  padding-top: 50px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 99;

    width: 100%;
    padding: #{$section-gutter} 0;
    .hero-c{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  .hero-content {
    margin-right: 0;
  }
  .hero-content-text{
    p{
      @include fluid-type(768px, 1440px, 18px, 20px);
      line-height: 32px;
      max-width: 540px;
    }
    h1 + p{
      margin: 48px 0 0;
    }
    p+p{
      margin-top: 20px;
    }
  }
  .careers-img{
    margin-left: 140px;
    width: 285px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.second-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .content {
    width: 540px;
    p {
      font-size: 18px;
      line-height: 30px;
    }

    p + p {
      margin-top: 20px;
    }
  }

  .image {
    width: 310px;
    margin-right: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}


/* MISSION */
.mission {
  padding-top: 50px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  > .d-flex {
    position: relative;
    z-index: 99;

    width: 100%;
    padding: #{$section-gutter} 0;
    .hero-c{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  .hero-content {
    margin-right: 0;
  }
}

.mission-image{
  margin-top: 100px;
  width: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.split-section{
  margin: 40px 0 0;
  display: flex;
  justify-content: space-between;
  .content{
    width: 540px;
    h4 + p{
      margin-top: 24px;
    }
  }
}

/* SCORE */


#score {
  padding-top: 200px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #191C1F;
  > .d-flex {
    position: relative;
    z-index: 99;

    width: 100%;
    padding: #{$section-gutter} 0;
    .hero-c{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  .background {
    width: auto;
    height: 640px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }
    .score-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h1{
        text-align: center;
        margin-bottom: 48px;
        color: white;
        font-size: 130px;
        font-family: "Graphik Semibold", sans-serif;
      }
      h2{
        max-width: 740px;
        text-align: center;
        margin: auto;
        font-family: "Graphik Semibold", sans-serif;
        color: white;
      }
    }
  }
}
.img-pagination-1{
  &:before{
    position: absolute;
    content: "";
    background: url("../img/separator.svg") no-repeat bottom -18rem right 50%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
  }
}
.img-pagination-2{
  &:before{
    position: absolute;
    content: "";
    background: url("../img/separator.svg") no-repeat top -5rem right 50%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
  }
}
.img-pagination-3{
  &:before{
    position: absolute;
    content: "";
    background: url("../img/separator.svg") no-repeat bottom -5rem right 50%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
  }
}
.img-pagination-4{
  &:before{
    position: absolute;
    content: "";
    background: url("../img/separator-half.png") no-repeat top -22rem right 50%;
    background-size: 60px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
  }
}
.sec-formalised{
  padding: 230px 0;
  .background {
    width: auto;
    height: 640px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }
    .score-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      h1{
        text-align: center;
        margin-bottom: 48px;
        color: white;
        font-size: 130px;
        font-family: "Graphik Semibold", sans-serif;
      }
      h2{
        max-width: 740px;
        text-align: center;
        margin: auto;
        font-family: "Graphik Semibold", sans-serif;
        color: white;
      }
    }
  }
}

.center-section{
  padding: 50px 26px;
  text-align: center;
  p{
    @include fluid-type(768px, 1440px, 20px, 24px);
    line-height: 35px;
    letter-spacing: -0.56px;
  }
  h3 + p{
    margin-top: 40px;
  }
}

.what-is-hy-sc{
  align-items: center;
  display: flex;
  justify-content: flex-end;
  .content{
    h2 + h3{
      margin-top: 50px;
    }
    h3{
      width: 540px;
      font-family: "Graphik Semibold", sans-serif;
      line-height: 44px;
      letter-spacing: -0.56px;
    }
    .cards{
      margin-top: 30px;
      .card{
        margin-top: 50px;
        width: 477px;
        .content{
          margin-top: 25px;
          p{
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
  }
  .image{
    margin-left: 30px;
    width: 650px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.how{
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .content{
    width: 540px;
    h2 + p{
      margin-top: 50px;
    }
    p{
      font-size: 20px;
      line-height: 30px;
    }
  }
  .image{
    width: 476px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.badge-section-content{
  h2 + p{
    margin-top: 16px;
  }
  p{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.42px;
    color: #6E7487;
  }
}
#badges-1 {
  margin-top: 40px;
  margin-bottom: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#badges-1 .badges-wrap {
  display: block;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  overflow: hidden;
}

#badges-1 .badges-wrap ul {
  display: block;
  list-style: none;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

#badges-1 .badges-wrap ul li {
  display: flex;
  float: left;
  position: relative;
  width: 100px;
  height: 80px;
  margin-left: 30px;

  .badge {
    width: 80px;
    height: 80px;
  }
}
#badges-1 .badges-wrap ul li img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-self: center;
  max-width: 100%;
  max-height: 100%;
  -webkit-transition: 0 linear left;
  -moz-transition: 0 linear left;
  transition: 0 linear left;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  //filter: alpha(opacity=65);
  //opacity: 0.65;
}
#badges-1 .badges-wrap ul li img:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1.0;
}

.section-quote{
  text-align: center;
  max-width: 840px;
  margin: auto;
  h2{
    letter-spacing: 0;
    line-height: 66px;
  }
}

.effective-section-content{

  margin-bottom: 100px;
}

.essential{
  display: flex;
  justify-content: space-between;
  .card{
    width: 540px;
    .content{
      p{
        font-size: 20px;
        line-height: 30px;
      }
    }
    .icon{
      width: 100px;
      height: 100px;
      margin-bottom: 30px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.dream-job{
  margin: 70px 0;
  &-cards{
    display: flex;
    justify-content: flex-start;
    .card{
      .content{
        p{
          font-size: 20px;
          line-height: 30px;
        }
      }
      .icon{
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &:first-of-type{
        width: 470px;
        margin-right: 60px;
      }
      &:nth-of-type(2){
        width: 350px;
      }
    }
  }
}
.features{
  .feature{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content{
      width: 634px;
      p{
        font-size: 20px;
        line-height: 30px;
      }
    }
    .icon{
      width: 120px;
      height: 120px;
      margin-right: 50px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}

.start-build{
  margin-top: 50px;
  text-align: center;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
  h2{
    line-height: 66px;
    margin-bottom: 60px;
  }
}